import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledButton } from '~/shared/components/Button/styled';
import { PlayButtonProps } from './Video';

interface StyledVideoContainerProps {
    playing?: boolean;
    cover?: boolean;
    controls?: boolean;
    theaterMode?: boolean;
}

export const StyledVideoAspect = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: '100%',
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export const StyledVideoContainer = styled.div<StyledVideoContainerProps>(
    {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    switchProp('controls', {
        true: {
            pointerEvents: 'auto',
        },
        false: {
            pointerEvents: 'none',
        },
    }),
    ifProp('theaterMode', {
        height: '100%',
    }),
    ifProp('playing', {
        cursor: 'pointer',
    }),
    ifProp('cover', {
        height: '100%',
    }),
);

export const StyledPlayButton = styled(StyledButton)<PlayButtonProps>(
    {
        border: 'none',
        appearance: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'opacity 0.1s, transform 0.1s ease-in-out',
        color: 'white',
        svg: {
            width: '80px',
            height: '80px',
        },
    },
    switchProp('playing', {
        true: {
            opacity: 0,
            pointerEvents: 'none',
        },
        false: {
            opacity: 1,
        },
    }),
);
