import { CSSObject, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import Checkmark from '~/icons/checkmark.svg';
import Eye from '~/icons/eye.svg';
import EyeClose from '~/icons/eye-close.svg';
import Spinner from '~/icons/spinner.svg';

type StyledInputProps = {
    withLabel: boolean;
    hideField?: boolean;
    isValid?: boolean;
};

type StyledLabelProps = {
    hasFocus?: boolean;
    isActive?: boolean;
    isValid?: boolean;
    required?: boolean;
};

type StyledInputFieldWrapperProps = {
    isValid: boolean;
};

export const inputRequiredStyle: CSSObject = {
    '::after': {
        content: "' *'",
    },
};

export const StyledInputWrapper = styled.div({});

export const StyledInput = styled.input<StyledInputProps>(
    ({ theme }) => ({
        ...theme.mixins.useTextStyle('formInput'),
        borderRadius: theme.sizes.cornerRadius,
        height: theme.metrics.medium,
        color: theme.colors.black,
        border: 0,
        lineHeight: theme.lineHeights.single,
        position: 'relative',
        cursor: 'type',
        outline: 0,
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'left',
        display: 'block',
        padding: `0 ${theme.space[3]}`,
        '&::placeholder': {
            transition: '0.1s color',
            color: theme.colors.grey70,
        },
        '&:empty:placeholder-shown::placeholder': {
            color: 'transparent',
        },
        '&:focus:empty::placeholder': {
            color: theme.colors.grey70,
        },
        '&:not(:placeholder-shown) ~ label, &:focus ~ label': {
            backgroundColor: theme.colors.white,
            paddingLeft: theme.space[1],
            paddingRight: theme.space[1],
            transform: 'translateY(-30px)',
            ...theme.mixins.useTextStyle('formsLabelActive'),
        },
        '::-webkit-calendar-picker-indicator:focus-visible': {
            boxShadow: theme.shadows.accessibility,
        },
    }),
    ifProp({ withLabel: false }, ({ theme }) => ({
        [`&:empty:placeholder-shown::placeholder`]: {
            color: theme.colors.black,
        },
    })),
    ifProp('hideField', () => ({
        height: 0,
        opacity: 0,
        padding: 0,
        border: 0,
        margin: 0,
    })),
);

export const StyledLabel = styled.label<StyledLabelProps>(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: theme.space[3],
        ...theme.mixins.useTextStyle('formsLabel'),
        transition: '0.1s transform, 0.1s width',
        color: theme.colors.black,
        transform: 'translateY(-50%)',
        maxWidth: `calc(100% - ${theme.space[3]} - ${theme.space[3]})`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transformOrigin: 'left top',
        pointerEvents: 'none',
    }),
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.negative,
    })),
    ifProp('required', { ...inputRequiredStyle }),
);

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>(
    ({ theme, isValid }) => ({
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: 'auto min-content',
        borderRadius: theme.sizes.cornerRadius,
        alignItems: 'center',

        border: `1px solid ${!isValid ? theme.colors.negative : theme.colors.black}`,
        backgroundColor: theme.colors.white,
        [`&:active ${StyledLabel}`]: {
            color: isValid ? theme.colors.black : theme.colors.negative,
        },
        ':has(:focus-visible)': {
            boxShadow: theme.shadows.accessibility,
        },
    }),
);

export const StyledAdditionalContent = styled.span(({ theme }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    svg: {
        overflow: 'visible',
    },
    button: {
        borderTopRightRadius: `calc(${theme.sizes.cornerRadius} - 2px)`,
        borderBottomRightRadius: `calc(${theme.sizes.cornerRadius} - 2px)`,
    },
}));

export const StyledWrapper = styled.div(() => ({
    position: 'relative',
    flexGrow: 1,
}));

export const InputCheckmark = styled(Checkmark)(({ theme }) => ({
    width: '18px',
    height: '18px',
    marginRight: 'calc(' + theme.space[3] + ' - ' + theme.space[1] + ')',
    marginTop: '2px',
}));

export const InputViewPassword = styled(Eye)(({ theme }) => ({
    width: '18px',
    height: '18px',
    marginRight: 'calc(' + theme.space[3] + ' - ' + theme.space[1] + ')',
    marginTop: '2px',
    cursor: 'pointer',
    ':focus-visible': {
        boxShadow: theme.shadows.accessibility,
    },
}));

export const InputHidePassword = styled(EyeClose)(({ theme }) => ({
    width: '18px',
    height: '18px',
    marginRight: 'calc(' + theme.space[3] + ' - ' + theme.space[1] + ')',
    marginTop: '2px',
    cursor: 'pointer',
    ':focus-visible': {
        boxShadow: theme.shadows.accessibility,
    },
}));

const ROTATING_ANIMATION = keyframes({
    to: { transform: 'rotate(360deg);' },
});

export const InputSpinner = styled(Spinner)(({ theme }) => ({
    width: '18px',
    height: '18px',
    marginRight: 'calc(' + theme.space[3] + ' - ' + theme.space[1] + ')',
    marginTop: '2px',
    animation: `${ROTATING_ANIMATION} 1.5s infinite`,
}));
